import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div id="privacy-policy-content">
      <h1>PRIVACY NOTICE</h1>

      <p>
        <strong>Last updated August 05, 2020</strong>
      </p>
      <p>
        Thank you for choosing to be part of our community at Landslide (“
        <strong>Company</strong>”, “<strong>we</strong>”, “<strong>us</strong>
        ”, or “<strong>our</strong>”). We are committed to protecting your personal information and your right to privacy. If you have any questions
        or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at
        support@landslide2020.org.
      </p>
      <p>
        When you visit our website{' '}
        <a href="https://landslide2020.org" target="_blank" rel="noreferrer">
          https://landslide2020.org
        </a>{' '}
        (the "<strong>Website</strong>"), use our mobile application, as the case may be (the "<strong>App</strong>") and more generally, use any of
        our services (the "<strong>Services</strong>", which include the Website and App), we appreciate that you are trusting us with your personal
        information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what
        information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as
        it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
      </p>
      <p>
        This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as
        well as any related services, sales, marketing or events.
      </p>
      <p>
        <strong>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</strong>
      </p>
      <h2>WHAT INFORMATION DO WE COLLECT?</h2>

      <h2>Personal information you disclose to us</h2>

      <p>
        <strong>
          <em>In Short: We collect information that you provide to us.</em>
        </strong>
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate in activities on the Services (such as by posting messages in our
        online forums or entering competitions, contests or giveaways) or otherwise when you contact us.
      </p>
      <p>
        The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the
        products and features you use. The personal information we collect may include the following:
      </p>
      <p>
        <strong>Personal Information Provided by You.</strong> We collect names; phone numbers; email addresses; mailing addresses; and other similar
        information.
      </p>
      <p>
        All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal
        information.
      </p>
      <h2>Information automatically collected</h2>

      <p>
        <strong>
          <em>
            In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected
            automatically when you visit our Services.
          </em>
        </strong>
      </p>
      <p>
        We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you
        use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes.
      </p>
      <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic usage and performance information our servers automatically
          collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may
          include your IP address, device information, browser type and settings and information about your activity in the Services (such as the
          date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use),
          device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
        </li>
      </ul>
      <h2>HOW DO WE USE YOUR INFORMATION?</h2>

      <p>
        <strong>
          <em>
            In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent.
          </em>
        </strong>
      </p>
      <p>
        We use personal information collected via our Services for a variety of business purposes described below. We process your personal
        information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with
        your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose
        listed below.
      </p>
      <p>We use the information we collect or receive:</p>
      <ul>
        <li>
          <strong>To facilitate account creation and logon process.</strong> If you choose to link your account with us to a third-party account (such
          as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account
          creation and logon process for the performance of the contract.
        </li>

        <li>
          <strong>To post testimonials.</strong> We post testimonials on our Services that may contain personal information. Prior to posting a
          testimonial, we will obtain your consent to use your name and the consent of the testimonial. If you wish to update, or delete your
          testimonial, please contact us at support@landslide2020.org and be sure to include your name, testimonial location, and contact information.
        </li>

        <li>
          <strong>Request feedback.</strong> We may use your information to request feedback and to contact you about your use of our Services.
        </li>

        <li>
          <strong>To enable user-to-user communications.</strong> We may use your information in order to enable user-to-user communications with each
          user's consent.
        </li>

        <li>
          <strong>To manage user accounts</strong>. We may use your information for the purposes of managing our account and keeping it in working
          order.
        </li>

        <li>
          <strong>To send administrative information to you. </strong>We may use your personal information to send you product, service and new
          feature information and/or information about changes to our terms, conditions, and policies.
        </li>

        <li>
          <strong>To protect our Services.</strong> We may use your information as part of our efforts to keep our Services safe and secure (for
          example, for fraud monitoring and prevention).
        </li>

        <li>
          <strong>
            To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection
            with our contract.
          </strong>
        </li>

        <li>
          <strong>To respond to legal requests and prevent harm. </strong>If we receive a subpoena or other legal request, we may need to inspect the
          data we hold to determine how to respond.
        </li>

        <li>
          <strong>Fulfill and manage your orders.</strong> We may use your information to fulfill and manage your orders, payments, returns, and
          exchanges made through the Services.
        </li>

        <li>
          <strong>Administer prize draws and competitions. </strong>We may use your information to administer prize draws and competitions when you
          elect to participate in our competitions.
        </li>

        <li>
          <strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information to provide you with the requested
          service.
        </li>

        <li>
          <strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and solve any
          potential issues you might have with the use of our Services.
        </li>
      </ul>
      <h2>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>

      <p>
        <strong>
          <em>
            In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to
            fulfill business obligations.
          </em>
        </strong>
      </p>
      <p>We may process or share your data that we hold based on the following legal basis:</p>
      <ul>
        <li>
          <strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information in a specific
          purpose.
        </li>

        <li>
          <strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business
          interests. This use may include, but is not limited to, sharing your data with partner organizations such as political campaigns or
          political action committees.
        </li>

        <li>
          <strong>Performance of a Contract: </strong>Where we have entered into a contract with you, we may process your personal information to
          fulfill the terms of our contract.
        </li>

        <li>
          <strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with
          applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a
          subpoena (including in response to public authorities to meet national security or law enforcement requirements).
        </li>

        <li>
          <strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action
          regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and
          illegal activities, or as evidence in litigation in which we are involved.
        </li>
      </ul>
      <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
      <ul>
        <li>
          <strong>Business Transfers.</strong> We may share or transfer your information in connection with any merger, sale of company assets,
          financing, or acquisition of all or a portion of our business to another company.
        </li>
      </ul>
      <h2>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

      <p>
        <strong>
          <em>In Short: We may use cookies and other tracking technologies to collect and store your information.</em>
        </strong>
      </p>
      <p>
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
      </p>
      <h2>HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

      <p>
        <strong>
          <em>
            In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise
            required by law.
          </em>
        </strong>
      </p>
      <p>
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer
        retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require
        us keeping your personal information for longer than the period of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information,
        or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store
        your personal information and isolate it from any further processing until deletion is possible.
      </p>
      <h2>HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

      <p>
        <strong>
          <em>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</em>
        </strong>
      </p>
      <p>
        We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we
        process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information
        storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized
        third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do
        our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should
        only access the Services within a secure environment.
      </p>
      <h2>WHAT ARE YOUR PRIVACY RIGHTS?</h2>

      <p>
        <strong>
          <em>In Short: You may review, change, or terminate your account at any time.</em>
        </strong>
      </p>
      <h2>Account Information</h2>

      <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
      <p>■ Contact us using the contact information provided.</p>
      <p>
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we
        may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use
        and/or comply with applicable legal requirements.
      </p>
      <p>
        <strong>
          <span>Cookies and similar technologies:</span>{' '}
        </strong>
        Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to
        reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out
        of interest-based advertising by advertisers on our Services visit{' '}
        <a href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">
          http://www.aboutads.info/choices/
        </a>
        .
      </p>
      <h2>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

      <p>
        <strong>
          <em>In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em>
        </strong>
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and
        obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties
        for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately
        preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us
        using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of
        unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information
        provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from
        all our systems (e.g. backups, etc.).
      </p>
      <h2>CCPA Privacy Notice</h2>

      <p>The California Code of Regulations defines a "resident" as:</p>
      <p>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</p>
      <p>
        (2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose
      </p>
      <p>All other individuals are defined as "non-residents."</p>
      <p>If this definition of "resident" applies to you, certain rights and obligations apply regarding your personal information.</p>
      <p>
        <strong>What categories of personal information do we collect?</strong>
      </p>
      <p>We have collected the following categories of personal information in the past twelve (12) months:</p>

      <table>
        <thead>
          <tr>
            <td>
              <strong>Category</strong>
            </td>
            <td>
              <strong>Examples</strong>
            </td>
            <td>
              <strong>Collected</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>A. Identifiers</td>
            <td>
              Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online
              identifier, Internet Protocol address, email address and account name
            </td>
            <td>YES</td>
          </tr>
          <tr>
            <td>B. Personal information categories listed in the California Customer Records statute</td>
            <td>Name, contact information, education, employment, employment history and financial information</td>
            <td>YES</td>
          </tr>
          <tr>
            <td>C. Protected classification characteristics under California or federal law</td>
            <td>Gender and date of birth</td>
            <td>YES</td>
          </tr>
          <tr>
            <td>D. Commercial information</td>
            <td>Transaction information, purchase history, financial details and payment information</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>E. Biometric information</td>
            <td>Fingerprints and voiceprints</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity</td>
            <td>
              Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems
              and advertisements
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>G. Geolocation data</td>
            <td>Device location</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
            <td>Images and audio, video or call recordings created in connection with our business activities</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information</td>
            <td>
              Business contact details in order to provide you our services at a business level, job title as well as work history and professional
              qualifications if you apply for a job with us
            </td>
            <td>NO</td>
          </tr>
          <tr>
            <td>J. Education Information</td>
            <td>Student records and directory information</td>
            <td>NO</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information</td>
            <td>
              Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </td>
            <td>YES</td>
          </tr>
        </tbody>
      </table>

      <p>
        We may also collect other personal information outside of these categories in instances where you interact with us in-person, online, or by
        phone or mail in the context of:
      </p>
      <ul>
        <li>Receiving help through our customer support channels;</li>

        <li>Participation in customer surveys or contests; and</li>

        <li>Facilitation in the delivery of our Services and to respond to your inquiries</li>
      </ul>
      <p>
        <strong>How do we use and share your personal information?</strong>
      </p>
      <p>More information about our data collection and sharing practices can be found in this privacy notice.</p>
      <p>You may contact us by email at support@landslide2020.org, or by referring to the contact details at the bottom of this document.</p>
      <p>
        If you are using an authorized agent to exercise your right to opt-out, we may deny a request if the authorized agent does not submit proof
        that they have been validly authorized to act on your behalf.
      </p>
      <p>
        <strong>Will your information be shared with anyone else?</strong>
      </p>
      <p>
        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each
        service provider is a for-profit entity that processes the information on our behalf.
      </p>
      <p>
        We may use your personal information for our own business purposes, such as for undertaking partnerships and collaborations to fulfill
        Landslide’s mission or for internal research for technological development and demonstration. This is not considered to be "selling" of your
        personal data.
      </p>
      <p>
        Landslide has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve
        (12) months. Landslide will not sell personal information in the future belonging to website visitors, users and other consumers.
      </p>
      <h2>Your rights with respect to your personal data</h2>

      <p>
        <span>Right to request deletion of the data - Request to delete</span>
      </p>
      <p>
        You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer
        of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be required to
        protect against illegal activities.
      </p>
      <p>
        <span>Right to be informed - Request to know</span>
      </p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li>whether we collect and use your personal information;</li>

        <li>the categories of personal information that we collect;</li>

        <li>the purposes for which the collected personal information is used;</li>

        <li>whether we sell your personal information to third parties;</li>

        <li>the categories of personal information that we sold or disclosed for a business purpose;</li>

        <li>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</li>

        <li>the business or commercial purpose for collecting or selling personal information.</li>
      </ul>
      <p>
        In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer request.
      </p>
      <p>
        <span>Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights</span>
      </p>
      <p>We will not discriminate against you if you exercise your privacy rights.</p>
      <p>
        <span>Verification process</span>
      </p>
      <p>
        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide information so that we can match it with the information you have
        previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we
        can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g.
        phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent
        possible, we will avoid requesting additional information from you for the purposes of verification. If, however, if we cannot verify your
        identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying
        your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish
        verifying you.
      </p>
      <p>
        <span>Other privacy rights</span>
      </p>
      <ul>
        <li>you may object to the processing of your personal data</li>

        <li>
          you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data
        </li>

        <li>
          you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
        </li>

        <li>
          you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will
          act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.
        </li>
      </ul>
      <p>
        To exercise these rights, you can contact us by email at{' '}
        <a href="mailto:support@landslide2020.org" target="_blank" rel="noreferrer">
          support@landslide2020.org
        </a>
        , or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like
        to hear from you.
      </p>
      <h2>DO WE MAKE UPDATES TO THIS NOTICE?</h2>

      <p>
        <strong>
          <em>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
        </strong>
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated
        version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by
        prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice
        frequently to be informed of how we are protecting your information.
      </p>
      <h2>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

      <p>If you have questions or comments about this notice, you may email us at support@landslide2020.org.</p>
      <h2>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>

      <p>
        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change
        that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please email
        support@landslide2020.org. We will respond to your request within 30 days.
      </p>
    </div>
  )
}
